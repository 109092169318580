@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
body {
  font-family: 'DM Sans', sans-serif;
}

option {
  color: black;
}

.custom-quill-editor {
  border-radius: 15px;
  overflow: hidden;
}

.custom-quill-editor .ql-container,
.custom-quill-editor .ql-toolbar {
  border: 1px solid #e2e8f0 !important;
}

.custom-quill-editor .ql-toolbar {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: none !important;
}

.custom-quill-editor .ql-container {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.perxify-price-input {
  --number-input-stepper-width: none !important;
  --number-input-input-padding: 0 !important;
}
.perxify-price-input input {
  padding: 0 5px !important;
}
