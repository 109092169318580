.print-only {
  display: none !important;
}

.no-print {
  display: block;
}

@media print {
  * {
    background: white !important;
  }
  body {
    background: white !important;
    color: black !important;
    font-size: 12pt;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .print-only {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  nav,
  .sidebar,
  .navbar,
  .footer,
  button,
  .chakra-button,
  .hide-on-print,
  .card-footer,
  [role='navigation'],
  [data-testid='sidebar'],
  .chakra-menu,
  .chakra-modal__overlay {
    display: none !important;
  }

  button[onClick*='handlePrint'] {
    display: none !important;
  }

  [as='MdPrint'],
  [as='MdDownload'],
  [as='MdShare'] {
    display: none !important;
  }

  .container,
  .main-content,
  .content,
  .chakra-container,
  #root {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
  }

  .card,
  .chakra-card {
    border: 1px solid #ddd !important;
    break-inside: avoid;
    page-break-inside: avoid;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 1cm !important;
    background-color: white !important;
  }

  table {
    border-collapse: collapse !important;
    width: 100% !important;
    margin-bottom: 1cm !important;
  }

  th,
  td {
    border: 1px solid #ddd !important;
    padding: 8px !important;
    text-align: left !important;
  }

  th {
    background-color: #f2f2f2 !important;
    font-weight: bold !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img,
  table,
  figure {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    break-after: avoid;
    margin-top: 1cm;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  [bgGradient*='brand.400'],
  [bgGradient*='brand.600'] {
    background: #4318ff !important;
    color: white !important;
  }

  .order-receipt,
  [ref='componentRef'] {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .chakra-grid {
    display: block !important;
  }

  .invoice-detail,
  [path*='payouts'] {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  [size='lg'] {
    font-size: 24pt !important;
    margin-bottom: 20px !important;
  }

  [size='md'] {
    font-size: 18pt !important;
    margin: 15px 0 !important;
  }

  .chakra-badge {
    border: 1px solid #000 !important;
    padding: 2px 8px !important;
    font-weight: bold !important;
  }

  .chakra-badge[colorScheme='green'] {
    background-color: #d4edda !important;
    color: #155724 !important;
    border-color: #155724 !important;
  }

  .chakra-badge[colorScheme='red'] {
    background-color: #f8d7da !important;
    color: #721c24 !important;
    border-color: #721c24 !important;
  }

  .chakra-badge[colorScheme='blue'] {
    background-color: #cce5ff !important;
    color: #004085 !important;
    border-color: #004085 !important;
  }

  .chakra-badge[colorScheme='orange'] {
    background-color: #fff3cd !important;
    color: #856404 !important;
    border-color: #856404 !important;
  }

  [gridArea*='1 / 2 / 2 / 3'] {
    display: none !important;
  }

  [gridArea*='2 / 1 / 3 / 3'] {
    display: none !important;
  }

  .page-break {
    page-break-before: always;
  }

  .print-landscape {
    size: landscape;
  }

  .print-portrait {
    size: portrait;
  }

  .print-header,
  .print-header a,
  .print-header .chakra-link,
  .print-header h1,
  .print-header h2,
  .print-header h3 {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
  }

  body:has(.invoice-detail) .print-header,
  [path*='payouts'] .print-header {
    display: none !important;
  }

  @page {
    margin: 0.75cm;
    size: A4;

    @bottom-center {
      content: 'Printed from Perxify - www.perxify.com';
      font-size: 9pt;
      color: #888;
    }

    /*
    @bottom-right {
      content: 'Page ' counter(page);
      font-size: 9pt;
      color: #888;
    }
    */
  }
}
